import axios, { AxiosResponse } from 'axios';
import { USE_LOCAL_REQUESTS } from 'Screens/ProjectDashboard/params';
import moment from 'moment';
import { generateSDCardImage, getSDCardImageStatus } from 'Utils/SOMApi';
import { GetSdCardImageStatusResponse } from 'Utils/UtilsTypes';
import { AWS_CONFIG } from 'aws/config';
import { api } from 'Services/api';
import { Bookmark, Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  DataGraphRequest,
  DeviceToSubType,
  IoTDeviceType,
} from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import { DASHBOARD_CURRENT_DEPLOYMENT } from 'config';
import { NavigateFunction } from 'react-router-dom';
import { ValuesOf } from '@electreon_ui/shared/types/globals';
import UserStore from '@electreon_ui/shared/stores/userStore/UserStore';
import { DeviceSearchResultsManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import AuthStore from '@electreon_ui/shared/stores/authStore/AuthStore';
import ProjectStore from '@electreon_ui/shared/stores/projectStore/ProjectStore';
import { useQuery } from '@tanstack/react-query';
import { rootStore } from 'MobxStores/context';
import { ArgTypes } from '@storybook/react';
// import { OcppDeviceSearchResults } from '@electreon/electreon-ocpp-device-metadata-service-gen-ts-client';

const projectDashboardLogoBlobPath = `${AWS_CONFIG.STATIC_ASSETS}/projects`;
const projectDashboardBlobPath = USE_LOCAL_REQUESTS
  ? `http://localhost:8868/blob_assets`
  : `${AWS_CONFIG.STATIC_ASSETS}/static-assets/${DASHBOARD_CURRENT_DEPLOYMENT}`;

export const handleSignOut = (
  navigate: NavigateFunction,
  authenticationModule: AuthStore,
  userStore: UserStore,
  projectStore: ProjectStore
) => {
  userStore.resetStore();
  projectStore.resetStore();
  authenticationModule.logout();
  navigate('/');
};

export const getSearchResults = ({ searchText }: { searchText: string }) => {
  return axios
    .all([
      api.deviceMetadata.mu.searchManagementUnits(searchText),
      api.deviceMetadata.vu.searchVehicleUnits(searchText),
      api.ocppChargersMetadata.ocpp.searchOcppChargers(searchText),
    ] as [
      Promise<AxiosResponse<DeviceSearchResultsManagementUnitModel, any>>,
      Promise<AxiosResponse<DeviceSearchResultsManagementUnitModel, any>>,
      Promise<AxiosResponse<DeviceSearchResultsManagementUnitModel, any>>,
    ])
    .then(
      axios.spread((res1, res2, res3) => {
        return [res1.data, res2.data, res3.data];
      })
    )
    .catch((err) => {
      return err;
    });
};

export const getProjectUnitList = ({
  projectId,
  limit,
  continuationToken,
  unitType,
}: {
  projectId: number | string;
  limit: number;
  continuationToken: string;
  unitType: 'mu' | 'vu';
}) => {
  if (unitType === 'mu') {
    return getProjectManagementUnits(projectId);
  } else {
    return getProjectVehicles(projectId);
  }
};

export const getProject = (projectId: number | string) => {
  // return api.metadata.projects.getProject(Number(projectId));
  const result = rootStore.queryClient.fetchQuery({
    queryKey: ['project', projectId],
    queryFn: () => api.metadata.projects.getProject(Number(projectId)),
    staleTime: 1000 * 60 * 5,
  });

  return result;
};

export const getProjectSegments = (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['projectSegments', projectId],
    queryFn: () => api.metadata.projects.getProjectSegments(Number(projectId)),
    staleTime: 1000 * 60 * 5,
  });
};

const mapProjects = (projects: Project[]) => {
  const projectIdToProjectMap = new Map<number, Project>();
  projects.forEach((project) => {
    if (!project.id) return;
    projectIdToProjectMap.set(project.id, project);
  });
  return projectIdToProjectMap;
};

export const useProjects = () => {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => api.metadata.projects.getProjects().then((res) => res.data),
    staleTime: Infinity,
    select: mapProjects,
  });
};

export const postProject = ({
  id,
  name,
  countryCode,
  timezoneStr,
  managementUnits,
  vehicleUnits,
}: Project) => {
  return api.metadata.projects.createProject({
    id,
    name,
    countryCode,
    timezoneStr,
    managementUnits,
    vehicleUnits,
  });
};

export const deleteProject = ({ projectId }: { projectId: number }) => {
  return api.metadata.projects.deleteProject(projectId);
};

export const lockDevice = ({ deviceId, sessionId }: { deviceId?: string; sessionId: string }) => {
  if (!deviceId) {
    return Promise.reject('Device Id is required');
  }
  return api.deviceControl.muRemoteControl.controlLock(deviceId, sessionId);
};
export const unlockDevice = ({ deviceId, sessionId }: { deviceId?: string; sessionId: string }) => {
  if (!deviceId) {
    return Promise.reject('Device Id is required');
  }
  return api.deviceControl.muRemoteControl.controlUnlock(deviceId, sessionId);
};

export const getTableData = (
  managementUnitIds: string[],
  vehicleUnitIds: string[],
  startDateTimeFull: string,
  endDateTimeFull: string,
  timezone: string
) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: [
      'analytics:tableData',
      managementUnitIds,
      vehicleUnitIds,
      startDateTimeFull,
      endDateTimeFull,
      timezone,
    ],
    queryFn: () =>
      api.deviceTelemetry.AnalyticsApi.getSummaryTable(
        managementUnitIds,
        vehicleUnitIds,
        startDateTimeFull,
        endDateTimeFull,
        timezone
      ),
    staleTime: Infinity,
  });
};

export const getEventsTimeSeries = (
  startTime: string,
  endTime: string,
  deviceIds: Array<string>,
  selectedEvents: Array<string>,
  timezoneStr: string,
  eventsPayload?: boolean
) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: [
      'analytics:eventsTimeSeries',
      startTime,
      endTime,
      deviceIds,
      selectedEvents,
      timezoneStr,
      eventsPayload,
    ],
    queryFn: () =>
      api.deviceTelemetry.AnalyticsApi.getEventsTimeSeries(
        startTime,
        endTime,
        deviceIds,
        selectedEvents,
        timezoneStr,
        eventsPayload
      ),
    staleTime: Infinity,
  });
};

export const getProjectDeployment = (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['deployment', projectId],
    queryFn: () => api.metadata.projects.getProjectDeployment(Number(projectId)),
  });
};

export const getProjectParkingSpots = (depotId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['parkingSpots', depotId],
    queryFn: () => api.metadata.parkingSpots.getDepotParkingSpots(Number(depotId)),
  });
};

export const getProjectVehicles = (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['vuList'],
    queryFn: () => api.deviceMetadata.vu.getDevices(Number(projectId)),
    staleTime: 1000 * 5,
  });
};

export const getProjectManagementUnits = (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['muList'],
    queryFn: () => api.deviceMetadata.mu.getProjectManagementUnits(Number(projectId)),
    staleTime: 1000 * 5,
  });
};

export const getProjectOcppChargers = (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['ocppList'],
    queryFn: () => api.ocppChargersMetadata.ocpp.getProjectOcppChargers(Number(projectId)),
    staleTime: 1000 * 5,
  });
};

export const getAnalyticsDataGraphParams = (subTypeList?: Array<DeviceToSubType>) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['analyticsParams', subTypeList],
    queryFn: () => api.deviceTelemetry.AnalyticsApi.getParameters(subTypeList).then((res) => res.data),
    staleTime: Infinity,
  });
};

export const fetchAdvancedParamsData = async (
  devicesAndParams: DataGraphRequest[],
  start: string,
  end: string,
  timeZoneStr: string
) => {
  try {
    return rootStore.queryClient.fetchQuery({
      queryKey: ['analytics:advancedParamsData', devicesAndParams, start, end, timeZoneStr],
      queryFn: () =>
        api.deviceTelemetry.AnalyticsApi.getTimeSeriesGraphByIds(
          start,
          end,
          timeZoneStr,
          devicesAndParams
        ).then((res) => res.data),
      staleTime: Infinity,
    });
  } catch (e) {
    console.error(`Error fetching advanced params data: ${e}`);
    return [];
  }
};

export const getAnalyticsDeviceActivity = async (
  deviceType: IoTDeviceType,
  deviceId: string,
  date: Date | null | string,
  padHours: number,
  timezone: string
) => {
  if ((deviceType !== 'MU' && deviceType !== 'VU' && deviceType !== 'OCPP') || !deviceId)
    return Promise.reject('Invalid device type');

  try {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return rootStore.queryClient.fetchQuery({
      queryKey: ['analytics:deviceActivity', deviceType, deviceId, formattedDate, date, padHours, timezone],
      queryFn: () =>
        api.deviceTelemetry.AnalyticsApi.getDeviceActivity(
          deviceType,
          deviceId,
          formattedDate,
          padHours,
          timezone
        ).then((res) => res.data),
      staleTime: Infinity,
    });
  } catch (e) {
    console.error(`Error fetching device activity: ${e}`);
    return Promise.reject(e);
  }
};

const pollSdCardCreationStatus = async (
  commandId?: string, // indentifies the sd card creation command
  interval?: number // interval in ms to poll the status
): Promise<GetSdCardImageStatusResponse> => {
  if (!commandId) return Promise.reject('commandId is required');

  let intervalRef: NodeJS.Timeout;
  return new Promise((resolve, reject) => {
    intervalRef = setInterval(async () => {
      try {
        const res = await getSDCardImageStatus(commandId);
        console.info(`SD Card creation status: ${res.status}`);
        if (res.status === 'SUCCESS') {
          clearInterval(intervalRef);
          resolve(res);
        } else if (
          res.status === 'FAILED' ||
          res.status === 'CANCELLED' ||
          res.status === 'TIMEOUT' ||
          res.status === 'CANCELLING'
        ) {
          clearInterval(intervalRef);
          reject(res);
        }
      } catch (e) {
        clearInterval(intervalRef);
        console.error(`Error in polling sd card creation status: ${JSON.stringify(e)}`);
        reject(e);
      }
    }, interval);
  });
};

export async function getSDCardImage(
  deviceId: string,
  version?: string | null,
  onError?: (error: any) => void
) {
  try {
    const generateSdCardResponse = await generateSDCardImage(deviceId, version || undefined);
    const commandId = generateSdCardResponse.commandId;
    const sdCardImageStatus = await pollSdCardCreationStatus(commandId, 5000).catch((e) => {
      onError && onError(e);
      console.error(`Error in polling sd card creation status: ${e}`);
      throw e;
    });
    if (!sdCardImageStatus?.resultUrl) throw new Error('No result url found');
    if (sdCardImageStatus.status === 'SUCCESS') {
      downloadFile(
        sdCardImageStatus.resultUrl,
        sdCardImageStatus.resultUrl.split('?')[0].split('/').pop(),
        onError
      );
    }
  } catch (error) {
    onError && onError(error);
    console.error(`Error in getSDCardImage request: ${JSON.stringify(error)}`);
    throw error;
  }
}

const TELEMETRY_EXPORT_STATUS = {
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  TIMED_OUT: 'TIMED_OUT',
  ABORTED: 'ABORTED',
  null: null,
} as const;

type TelemetryExportStatus = ValuesOf<typeof TELEMETRY_EXPORT_STATUS>;

async function pollGetRawDataStatus(
  arn: string,
  interval?: number // interval in ms to poll the status
) {
  if (!arn) return Promise.reject('arn is required');

  let intervalRef: NodeJS.Timeout;
  return new Promise((resolve, reject) => {
    intervalRef = setInterval(async () => {
      try {
        const res = await api.deviceTelemetry.TelemetryExportApi.getExportTelemetryStatus(arn);
        if (!res.data) throw new Error('No data found in pollGetRawDataStatus');
        const status = res.data.status as TelemetryExportStatus;
        console.info(`Raw data status: ${status}`);
        if (status === TELEMETRY_EXPORT_STATUS.SUCCEEDED) {
          if (!res.data.result) throw new Error('No result found');
          clearInterval(intervalRef);
          resolve(res.data.result);
        } else if (
          status === TELEMETRY_EXPORT_STATUS.ABORTED ||
          status === TELEMETRY_EXPORT_STATUS.FAILED ||
          status === TELEMETRY_EXPORT_STATUS.TIMED_OUT
        ) {
          clearInterval(intervalRef);
          reject(res);
        }
      } catch (e) {
        clearInterval(intervalRef);
        console.error(`Error in polling raw data status: ${JSON.stringify(e)}`);
        reject(e);
      }
    }, interval);
  });
}

type GetRawDataParams = {
  startTime: string;
  endTime: string;
  timezoneStr: string;
  rawDataExportRequestParams: Array<DataGraphRequest>;
  onError?: (error: any) => void;
  /** Temporary for the migration process & testing databricks */
  isDataBricks?: boolean;
};
export async function getRawData({
  startTime,
  endTime,
  timezoneStr,
  rawDataExportRequestParams,
  onError,
  isDataBricks,
}: GetRawDataParams) {
  try {
    if (isDataBricks) {
      const url = await api.deviceTelemetry.AnalyticsApi.exportTimeSeriesGraphByIds(
        startTime,
        endTime,
        timezoneStr,
        rawDataExportRequestParams
      );
      downloadFile(url.data, 'rawData.xlsx', onError);
      return;
    }

    const arn = (
      await api.deviceTelemetry.TelemetryExportApi.triggerExportTelemetry(
        startTime,
        endTime,
        timezoneStr,
        rawDataExportRequestParams
      )
    ).data?.executionArn;
    if (!arn) throw new Error('No executionArn found');

    // set polling interval with using the arn
    const excelRawDataUrl = await pollGetRawDataStatus(arn, 5000);
    if (!(typeof excelRawDataUrl === 'string')) throw new Error('No excelRawDataUrl found.');
    console.info(`File url: ${excelRawDataUrl}`);

    downloadFile(excelRawDataUrl, 'rawData.xlsx', onError);
  } catch (error) {
    onError && onError(error);
    console.error(`Error in getRawData request: ${JSON.stringify(error)}`);
    throw error;
  }
}

export function downloadFile(url: string, outputFileName?: string | null, onError?: (error: any) => void) {
  try {
    const link = document.createElement('a');
    link.href = url;
    const fileName = outputFileName || 'download';
    link.download = fileName;
    link.click();
  } catch (error) {
    onError && onError(error);
    console.error(`Error in downloadFile request: ${JSON.stringify(error)}`);
    throw error;
  }
}

// project dashboad specific requests

export const getProjectLogoImgPath = async (id: string | number, type: 'small' | 'main') => {
  try {
    const svgRes = await fetch(`${projectDashboardLogoBlobPath}/${id}/logo-${type}.svg`);
    if (svgRes.status === 200) return svgRes.url;
    const pngRes = await fetch(`${projectDashboardLogoBlobPath}/${id}/logo-${type}.png`);
    if (pngRes.status === 200) return pngRes.url;
    return '';
  } catch (e) {
    console.error(`Error fetching project logo. ${JSON.stringify(e, null, 2)}`);
    return '';
  }
};

export const getBlobImgPath = (projectId: string, relativeImgPath: string) => {
  return `${projectDashboardBlobPath}/projects/_${projectId}/images/${relativeImgPath}`;
};

export const getVehicleImagePath = (vehicleId: string) => {
  return `${projectDashboardBlobPath}/vehicles/${vehicleId}/image.png`;
};

export const getVehicleSmallImagePath = (vehicleId: string) => {
  return `${projectDashboardBlobPath}/vehicles/${vehicleId}/small_vehicle.png`;
};

export const getVehicleLogoImagePath = (vehicleId: string) => {
  return `${projectDashboardBlobPath}/vehicles/${vehicleId}/logo.png`;
};

export const getProjectDashboardSettings = async (projectId: number | string) => {
  return await rootStore.queryClient.fetchQuery({
    queryKey: ['dashboardSettings', projectId],
    queryFn: () =>
      fetch(`${projectDashboardBlobPath}/projects/_${projectId}/metadata.json`).then((res) => res.json()),
  });
};

export const getProjectSegmentsPath = async (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['segmentsPath', projectId],
    queryFn: () =>
      fetch(`${projectDashboardBlobPath}/projects/_${projectId}/segmentsPath.json`).then((res) => res.json()),
    staleTime: 1000 * 60 * 5,
  });
};

export const getVehicleSettings = async (vuId: string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['vehicleSettings', vuId],
    queryFn: () =>
      fetch(`${projectDashboardBlobPath}/vehicles/${vuId}/metadata.json`).then((res) => res.json()),
  });
};

export const getRoadPath = async (projectId: number | string) => {
  return await rootStore.queryClient.fetchQuery({
    queryKey: ['roadPath', projectId],
    queryFn: () =>
      fetch(`${projectDashboardBlobPath}/projects/_${projectId}/roadPath.json`).then((res) => res.json()),
  });
};

export const getMapBackgroundPath = async (projectId: number | string) => {
  return rootStore.queryClient.fetchQuery({
    queryKey: ['mapBackground', projectId],
    queryFn: () =>
      fetch(`${projectDashboardBlobPath}/projects/_${projectId}/background.geojson.json`).then((res) =>
        res.json()
      ),
    staleTime: 1000 * 60 * 5,
  });
};
