import { cn } from '@repo/ui';
import { observer } from 'mobx-react-lite';
import { ButtonGroup } from '@electreon_ui/shared';

export const TIME_RANGE_IN_MINUTES = {
  HOURS_24: 60 * 24,
  DAYS_7: 60 * 24 * 7,
  DAYS_30: 60 * 24 * 30,
  YEAR: 60 * 24 * 365,
} as const;

const alertsTimeRangeSelectorOptions: Array<{ displayValue: string; value: string | number }> = [
  { displayValue: 'LAST 24 HOURS', value: TIME_RANGE_IN_MINUTES.HOURS_24 },
  { displayValue: 'LAST 7 DAYS', value: TIME_RANGE_IN_MINUTES.DAYS_7 },
];

export const AlertsTimeRangeSelector: React.FC<{
  selectedTimeRange: string | number;
  setSelectedTimeRange: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
}> = observer(({ selectedTimeRange, setSelectedTimeRange, disabled }) => {
  return (
    <ButtonGroup
      disabled={disabled}
      options={alertsTimeRangeSelectorOptions}
      activeValue={selectedTimeRange}
      onClick={(value) => value && setSelectedTimeRange(Number(value))}
      className={cn('w-[200px] h-[30px] shadow-none [&_button]:!text-[17px]')}
      showCheckIcon={false}
    />
  );
});
